<template>
  <div style="width: 100%">
    <v-list dense style="width: 100%">
      <v-subheader style="font-size: 14px">Actions</v-subheader>
      <v-divider></v-divider>
      <v-list-item @click="exportCSV">
        <v-list-item-action>
          <v-icon small color="grey">file_download</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Export data to CSV </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="exportExcel">
        <v-list-item-action>
          <v-icon small color="grey">file_download</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Export data to Excel </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="resetTable">
        <v-list-item-action>
          <v-icon small color="grey">autorenew</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Reset Table </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :disabled="!bulkUpdateAvailable" @click="bulkUpdateModal=true">
        <v-list-item-action>
          <v-icon small color="grey">update</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Bulk Update </v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            {{ selectedRows.length }} rows selected
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>


    <v-dialog v-model="bulkUpdateModal" width="auto">
        <v-card v-if="bulkUpdateType">
            <v-toolbar flat color="transparent">
                <v-toolbar-title style="font-size: 16px">
                    {{ bulkUpdateType == 'Pending' ? 'Update Submitted Date' :
                        'Update Completed Date' }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon small @click="bulkUpdateModal = false" color="redPop">
                <v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <!--Date picker-->
                <v-date-picker v-model="bulkDate" scrollable style="margin: 5px;" color="primary">
                    <v-row justify="center" style="margin-top: 4px;">
                        <v-btn :disabled="!bulkDate" text color="primary" :loading="bulkUpdateLoading" @click="userAgreement=true">
                            Next
                        </v-btn>
                    </v-row>
                </v-date-picker>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="userAgreement" width="20vw">
      <v-card>
        <v-card-title
          >Disclaimer <v-spacer></v-spacer
          ><v-btn icon @click="userAgreement = false" color="primary"
            ><v-icon>close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text
          ><p>
            Before proceeding with the mass update of documents, please carefully review all entered data to ensure its accuracy. By continuing with this operation, you acknowledge and agree that any consequences arising from the incorrect entry of data, including but not limited to data corruption, loss of information, or unintended changes to documents, will be subject to your company's policies. These policies will enforce any necessary actions, including potential disciplinary measures, for errors or issues that arise as a result of inaccurately provided data. We strongly recommend double-checking all input before proceeding.
          </p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="bulkUpdateDates"
          >
            I agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    bulkUpdateAvailable: false,
    bulkDate: null,
    bulkUpdateLoading: false,
    bulkUpdateModal: false,
    bulkUpdateType: '',
    item: null,
    selectedRows: [],
    userAgreement: false
  }),
  mounted() {
    this.item = this.params;
    if (this.params.api) {
      this.params.api.addEventListener(
        "selectionChanged",
        this.rowSelectionChanged
      );
      this.params.api.addEventListener("filterChanged", this.filterListener);
    }
  },
  methods: {
    async bulkUpdateDates() {
      this.userAgreement = false;
        let obj = {};
        this.bulkUpdateLoading = true;
        if (
          this.selectedRows.length > 0 &&
          this.bulkUpdateType == "Pending"
        ) {
          obj = {
            submittedDate: this.bulkDate,
            shipmentDocumentIds: this.selectedRows.map((x) => x.id),
            shipmentId: this.selectedRows.map((x) => x.shipmentId),
            type: "Pending",
          };
        } else {
          obj = {
            completedDate: this.bulkDate,
            shipmentDocumentIds: this.selectedRows.map((x) => x.id),
            shipmentId: this.selectedRows.map((x) => x.shipmentId),
            type: "Submitted",
          };
        }
        console.log(obj);
        try {
          let res = await this.$API.bulkUpdateShipmentDocuments(obj);
          this.$message.success("Successfully updated!");
          if(this.bulkUpdateType == 'Pending') {
            for(let i= 0; i < this.selectedRows.length; i++) {
              this.selectedRows[i].submittedDate = this.bulkDate;
              this.selectedRows[i].status = 'Submitted';
              this.params.updateDocument(this.selectedRows[i]);
            }
          } else {
            for(let i= 0; i < this.selectedRows.length; i++) {
              this.selectedRows[i].completedDate = this.bulkDate;
              this.selectedRows[i].status = 'Complete';
              this.params.updateDocument(this.selectedRows[i]);
            }
          }
          this.bulkUpdateModal = false;
          this.bulkUpdateLoading = false;
        //   this.refresh();
        } catch (e) {
          this.$message.error("An error ocurred");
          this.bulkUpdateLoading = false;
        }
    },
    exportCSV() {
      this.params.exportCSV();
    },
    exportExcel() {
      this.params.exportExcel();
    },
    filterListener(event) {
      let filter = this.params.api.getFilterModel();
      if (
        filter["status"] &&
        (filter["status"].values.includes("Pending") ||
          filter["status"].values.includes("Submitted")) &&
        filter["document"] &&
        filter["document"].values.length == 1
      ) {
        this.bulkUpdateAvailable = true;
        this.bulkUpdateType = filter["status"].values[0];
      } else {
        this.bulkUpdateAvailable = false;
      }
      this.selectedRows = [];
      this.params.api.setGridOption('rowSelection',this.bulkUpdateAvailable?'multiple':false);
    },
    resetTable() {
      this.params.resetTable();
    },
    rowSelectionChanged(event) {
      if (event.source != "gridInitializing") {
        let rows = this.params.api.getSelectedRows();
        this.selectedRows = rows;
      } else {
        this.params.api.deselectAll();
        this.selectedRows = [];
      }
    },
  },
};
</script>